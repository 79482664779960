.main-card{height: calc(100vh - 50px);width: 100%;display: flex;align-items: center;justify-content: center;background-position: 50%;background-repeat: no-repeat;background-size: cover;}
.sign-in-section{display: flex;flex-direction: column;gap: 15px 0;}
.sign-in-section .heading-div{display: flex;flex-direction: column;gap: 0;justify-content: center;align-items: center;}
.sign-in-section .heading-div .welcome-title{color: #fff;font-size: 38px;line-height: normal;font-weight: 600;margin-bottom: 0;}
.sign-in-section .heading-div .sign-in-msg{color: #fff;text-align: center;font-size: 22px;line-height: 28px;font-weight: 400;margin-bottom: 0;}
.main-card .form-group {margin-bottom: 1rem;position: relative;}
.sign-in-section .sign-in-card{display: flex;flex-direction: column;border: 5px solid #fff;border-radius: 6px;padding: 6px;background: #1e4bb3;width: 285px;gap: 7px 0;}
.sign-in-section .sign-in-card .display-div{display: flex;width: 100%;align-items: center;}
.sign-in-section .sign-in-card .display-div .value{width: 100%;padding: 8px 4px 8px 12px;text-align: right;background: #fff;border: 1px solid #5c5b58;border-radius: 3px;font-size: 26px;color: #000;font-weight: 500;border-top-right-radius: 0;border-bottom-right-radius: 0;}
.sign-in-section .sign-in-card .display-div .value:focus-visible{outline: 0;}
.sign-in-section .sign-in-card .display-div .backspace-btn{border: 0;background: #143a4e;min-width: 55px;height: 57.46px;border: 1px solid #5c5b58;cursor: pointer;}
.sign-in-section .sign-in-card .display-div .backspace-btn .arrow{color: #fff;font-size: 18px;}
.sign-in-section .sign-in-card .number-pad-card{display: grid;grid-template-columns: repeat(3,1fr);gap: 4px;}
.sign-in-section .sign-in-card .number-pad-card .number-card.number:disabled{background: #878683;opacity: 0.75;}
.sign-in-section .sign-in-card .number-pad-card .number-card.number:disabled{color: #ddd;cursor: unset;}
.sign-in-section .sign-in-card .number-pad-card .number-card{color: #000;background: #fff;height: 80px;width: 100%;border: 0;cursor: pointer;font-size: 26px;font-weight: 500;border-radius: 2px;transition: all 0.2s ease-in-out;}
.sign-in-section .sign-in-card .number-pad-card .option-btn{background: #7f7d7f;border: 2px solid #94926a;border-radius: 2px;font-size: 14px;font-weight: 600;transition: all 0.2s ease-in-out;color: #fff;}
.sign-in-section .sign-in-card .number-pad-card .option-btn:disabled{border-color: #878683;background-color: #878683;color: #b5b5b5;cursor: no-drop;}
.sign-in-section .sign-in-card .number-pad-card .option-btn.next-btn{background: #198754;}
.sign-in-section .sign-in-card .number-pad-card .option-btn.next-btn:disabled{background: #7f7d7f;border: 2px solid #94926a;}

.main-card .card-form{width: 350px;background: #fff;padding: 0;}
.sign-in-section .sign-in-card .form-control{appearance: none; background-clip: padding-box; background-color: #fff; border: 1px solid #ced4da; border-radius: 0.3rem; color: #1e2a35; display: block;font-size: 1rem;font-weight: 400;line-height: 1.5;padding: 0.375rem 0.75rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;width: 100%;}
.main-card .card-form .number-div{display: flex;gap: 0 10px;align-items: center;padding: 0;}
.main-card .card-form .number-div .icon{font-size: 28px;display: flex;color: rgb(30, 42, 53);}
.main-card .card-form .number-div .number{font-size: 22px;font-weight: 500;line-height: 32px;color: rgb(30, 42, 53);}
.main-card .card-form .signin-form .btns{display: flex;align-items: center;}
.main-card .card-form .signin-form .custom-checkbox .checkbox{margin-right: 6px;}
.main-card .btn:hover{box-shadow: none !important;}
.main-card .btn-check:focus+ .main-card .btn-secondary, .main-card .btn-secondary:focus,.main-card .btn-secondary:hover{background-color: #555;border-color: #505050;color: #fff;}

.main-card .info-card{width: 400px;}
.main-card .info-card.service-info-card{width: 100%;}
.main-card .info-card .card-title .icon{font-size: 28px;display: flex;color: rgb(30, 42, 53);}
.main-card .info-card .card-title .number{font-size: 22px;font-weight: 500;line-height: 32px;color: rgb(30, 42, 53);}
.main-card .info-card .card .card-title{display: flex;gap: 0 10px;align-items: center;}
.main-card .info-card .card .card-body{display: flex;flex-direction: column;gap: 4px 0;}
.main-card .info-card .card .card-body .tab-content{display: flex;flex-direction: column;border: 1px solid #143a4e;}
.main-card .info-card .card .card-body .tab-content .tab-header{background: #143a4e;padding: 6px 12px;display: flex;align-items: center;gap: 0 10px;font-size: 18px;line-height: normal;color: #fff;}
.main-card .info-card .card .card-body .tab-content .tab-footer{display: flex;align-items: center;background: rgb(33 37 41 / 12%);padding: 6px 12px;}
.main-card .info-card .card .card-body .tab-content .tab-footer .text{margin-bottom: 0;}
.main-card .info-card .card .card-body .tab-content .tab-footer .add-service{display: flex;gap: 0 6px;text-decoration: none;align-items: center;color: #000;border: 0;background: transparent;}
.main-card .info-card .card .card-body .tab-content .tab-footer .add-service:focus-visible{outline: 0px;}
.main-card .info-card .card .card-body .tab-content .tab-footer .icon-bg{width: 25px;height: 25px;background-color: #2962ff;border-radius: 6px;display: flex;align-items: center;justify-content: center;color: #fff;}
.main-card .info-card .card .card-body .tab-content .tab-header .number-tag{width: 30px;height: 30px;border-radius: 50%;background: #fff;display: flex;align-items: center;justify-content: center;font-size: 14px;line-height: 1;color: #000;}
.main-card .info-card .card .card-body .tab-content .input{height: 100%;min-height: 25px;display: flex;justify-content: center;gap: 5px 7px;flex-wrap: wrap;}
.main-card .info-card .card .card-body .tab-content .input .service-tag{display: inline-block;background: rgb(41 98 255 / 20%);padding: 2px;color: #000;font-weight: 500;}
.main-card .info-card .card .card-body .tab-content .tab-body{padding: 4px;}
.main-card .info-card .card .card-body .tab-content .tab-body .checkbox-list{display: flex;flex-direction: column;height: 130px;overflow-y: scroll;}
.main-card .info-card .card .card-body .tab-content .tab-body .checkbox-list .checkbox-div{display: flex;align-items: center;padding: 10px 12px;border-bottom: 1px solid #ddd;justify-content: space-between;}
.main-card .info-card .card .card-body .tab-content .tab-body .checkbox-list .checkbox-div:nth-last-child(1){border-bottom: 0;}
.main-card .info-card .card .card-body .tab-content .tab-body .checkbox-list .checkbox-div .checkbox{display: flex;gap: 0 6px;align-items: center;}
.main-card .form-check-input:checked{background-color: #2962ff;border-color: #2962ff;}
.main-card .form-check-input{width: 17px;height: 17px;}
.main-card .info-card .card .card-body .tab-content .tab-body .checkbox-list .checkbox-div .form-check-label{font-size: 16px;color: #000;}
.main-card .info-card .card .card-body .tab-content .tab-body .checkbox-list  .waiting-text{font-size: 16px;color: #000;}
.main-card .info-card .card-footer .btns{display: flex;width: 100%;}
.main-card .info-card .card-footer .btns .send-btn{background: #143a4e;color: #fff;}


/* Modal CSS  */
.service-modal .row-height{height: 200px;overflow-y: scroll;}
.service-modal ::-webkit-scrollbar, .main-card ::-webkit-scrollbar{width: 5px;}
.service-modal ::-webkit-scrollbar-track, .main-card ::-webkit-scrollbar-track{background: #f1f1f1;}  
.service-modal ::-webkit-scrollbar-thumb, .main-card ::-webkit-scrollbar-thumb {background: #888;}  
.service-modal ::-webkit-scrollbar-thumb:hover, .main-card ::-webkit-scrollbar-thumb:hover{background: #555;}

.categories-row{display: flex;width: 100%;align-items: center;gap: 0 2px;position: relative;}
.categories-row .btns-group{display: grid;grid-template-columns: repeat(5, 1fr);gap: 0 2px;width: calc(100% - 50px);}
.categories-row .categories-dropdown-btn{width: 50px;height: 100%;}

@media (max-width:1199px) {
    .categories-row .btns-group{grid-template-columns: repeat(3, 1fr);}
}
@media (max-width:576px) {
    .categories-row .btns-group{grid-template-columns: repeat(2, 1fr);}
}

/* queue-modal */
.modal .right.modal-dialog {position: fixed;margin: auto;width: 350px;height: 100%;transform: translate3d(0%, 0, 0);}
.modal .right .modal-content {height: 100%;overflow-y: auto;}
.modal .right.modal-dialog {right: -350px;transition: all 0.3s ease-out !important;}
.modal.show .right.modal-dialog {right: -1px;top: -1px;}


.queue-modal .modal-content{border-radius: 0}
.queue-modal .modal-header{background: #fff;border-radius: 0;padding: 8px 10px;}
.queue-modal .modal-header .modal-title{display: flex;align-items: center;gap: 0 6px;font-size: 16px;font-weight: 600;}
.queue-modal .modal-header .icon-div{border: 1px solid #000;border-radius: 4px;height: 24px;width: 18px;}
.queue-modal .modal-header .icon-div i{color: #000;}

.queue-modal .modal-body{padding: 12px 6px 6px 6px;}
.queue-modal .modal-body .artist-card{display: flex;flex-direction: column;}
.queue-modal .modal-body .artist-card .header{display: flex;align-items: center;background: #21437d;padding: 8px 4px;justify-content: space-between;border-radius: 3px 3px 0 0;}
.queue-modal .modal-body .artist-card .header .quick-tabs{display: flex;align-items: center;gap: 0 6px;}
.queue-modal .modal-body .artist-card .header .quick-tabs .icn-div{width: 16px;height: 16px;background: #2a2a2a;display: flex;align-items: center;justify-content: center;border-radius: 3px;}
.queue-modal .modal-body .artist-card .header .quick-tabs .icn-div i{color: #fff;font-size: 10px;}
.queue-modal .modal-body .artist-card .header .quick-tabs .number{margin-bottom: 0;color: #fff;font-size: 18px;line-height: normal;}
.queue-modal .modal-body .artist-card .header .artist-name{margin-bottom: 0;color: #ffff;font-size: 18px;line-height: normal;}
.queue-modal .modal-body .artist-card .message-card{background: #fff;padding: 6px 4px;font-weight: 500;color: #666;font-size: 14px;border-radius: 0 0 3px 3px;}
.queue-modal .modal-body .artist-card .message-card .icon{margin-right: 6px;font-size: 14px;color: #000;}
.queue-modal .modal-body .artist-card .message-card .name{color: #153942;font-weight: 600;}

.queue-modal .modal-body .upcoming-app-text{margin: 14px 0 10px 0;padding-bottom: 4px;border-bottom: 1px solid #fff;text-transform: uppercase;color: #fff;font-size: 14px;font-weight: 500;width: fit-content;}

.queue-modal .modal-body .upcoming-app-cards{display: flex;flex-direction: column;gap: 6px 0;}
.queue-modal .modal-body .upcoming-app-cards .header{background: #dadada;padding: 8px 6px;}
.queue-modal .modal-body .upcoming-app-cards .header .artist-name{color: #666666;}
.queue-modal .modal-body .upcoming-app-cards .artist-card .header .quick-tabs .number{color: #666666;}
.queue-modal .modal-body .upcoming-app-cards .message-card{background: #fff;}
.queue-modal .modal-body .upcoming-app-cards .message-card .with-text{color: #073564;}
.queue-modal .modal-body .upcoming-app-cards .message-card .name{color: #073564;}
.queue-modal .modal-body{background: #1e2a35;}

.custome-label{font-size: 18px; font-weight: bold;}
.custome-form-group{display: flex; align-items: center; flex-direction: column; text-align: center;}
.custome-form-group input{text-align: center;}
.select-mdiv{display: flex; align-items: center; justify-content: center; gap: 5px; width: 100%;}

.MuiFormControl-root:nth-child(1){margin-bottom: 10px;}
.MuiInputBase-input{padding: 10px 0 10px 10px !important;}
.MuiButtonBase-root{padding: 10px 10px 10px 0 !important;}
.MuiInputBase-root{padding-right: 10px !important;}
.timepicker-td{padding-top: 15px;}

@media (max-width:425px) {
    .modal .right.modal-dialog {width: 290px;}
    .modal .right.modal-dialog {right: -290px;}
}


/* Thank you page  */
.thank-you-div{display: flex;align-items: center;justify-content: center;width: 100%;height: calc(100vh - 50px);flex-direction: column;}
.thank-you-div .icon-div .thanks-icon{width: 70px;height: 70px;}
.thank-you-div .thank-you-text{margin: 25px 0 15px 0;font-size: 38px;font-weight: 600;line-height: normal;color: #fff;}
.thank-you-div .thank-you-subtext{font-size: 18px;line-height: 22px;font-weight: 600;color: #fff;margin-bottom: 15px;}
.thank-you-div .p-text{margin-bottom: 0;color: #fff;font-size: 16px;line-height: 26px;max-width: 600px;text-align: center;font-weight: 400;}
@media (max-width:576px) {
    .thank-you-div .thank-you-text{font-size: 34px;}
    .thank-you-div .icon-div .thanks-icon{width: 65px;height: 65px;}
}
.react-datepicker-wrapper{width: 100%;}
.welcome-back{font-size: 16px;line-height: 26px;font-weight: 500;color: #000;}

.main-card-column{flex-direction: column;}
.main-card-column .welcome-title{color: #fff;font-size: 28px;line-height: normal;font-weight: 600;margin-bottom: 0; max-width: 350px; text-align: center; margin-bottom: 15px;}
.new-thank-you .thank-you-text{max-width: 600px; text-align: center; font-size: 28px !important;}

.new-thank-you .thank-you-text.thank-you-text-2{font-size: 22px !important; margin-top: 5px;}

.back-arrow-ab{text-decoration: none;background: none;border: 0; display: flex; align-items: center; justify-content: center; gap: 5px; margin-right: auto; font-weight: 600;}
.main-card .card-form .back-arrow-ab .icon {font-size: 18px; width: 30px; height: 30px; min-width: 30px; min-height: 30px;  background: #2962ff; display: flex; align-items: center; justify-content: center;  color: #fff; border-radius: 100%;}


.bg100{min-height: 100vh;}

.newdashboard-table.individual-table table tbody td{border: 0;}
.newdashboard-table .table-responsive{border-radius: 4px;}
.newdashboard-table .table{margin-bottom: 0;}

.employee-average table tbody th{width: 180px;}
.clock-summary-modal .modal-header, .clock-summary-modal .modal-footer{background: #F0F0F0;border: none;}
.clock-summary-modal table tbody tr:nth-last-child(1) td{border: 0;}
.MuiButtonBase-root{display: flex !important;align-items: center !important;justify-content: center !important;height: 34px !important;padding-right: 0 !important;width: 34px !important;line-height: 22px !important;}
.ts-emp-name{cursor: pointer;color: #2962ff;}
.ts-emp-name:hover{color: #0044ff;}

@media (min-width:992px) {
    /* Toggled Sidebar  */
    .sidebarArea{width: 70px;transition: all 0.2s ease;}
    .sidebarArea:hover{width: 260px;}
    .sidebarArea .sidebarNav .nav-link span{width: 0;opacity: 0;margin: 0 !important;white-space: nowrap;}
    .sidebarArea .sidenav-bg .nav-link{justify-content: center;align-items: center;}
    .sidebarArea .sidenav-bg .nav-link i{display: flex;}
    .sidebarArea:hover .sidenav-bg .nav-link{justify-content: flex-start;}
    .sidebarArea:hover .sidebarNav .nav-link span{width: fit-content;opacity: 1;margin-left: 16px !important;}
}

@media (min-width:1960px) {
    .scale15{transform: scale(1.3);}
    .new-thank-you .thank-you-text{font-size: 40px !important; max-width: 750px;}
    .new-thank-you .thank-you-text.thank-you-text-2{font-size: 30px !important;}
    .thank-you-div .icon-div .thanks-icon{height: 90px; width: 90px;}
    .new-overflow:has(.welcome-title){overflow: hidden;}
}

/* @media (min-width:1200px) and (orientation: portrait) {
    .scale15{transform: scale(2);}
    .new-thank-you .thank-you-text{font-size: 50px !important; max-width: 750px;}
    .new-thank-you .thank-you-text.thank-you-text-2{font-size: 40px !important;}
    .thank-you-div .icon-div .thanks-icon{height: 100px; width: 100px;}
    .new-overflow:has(.welcome-title){overflow: hidden;}
} */


@media (min-width:1195px) and (max-width:1260px) {

    .main-card:has(.scale15), .main-card.scale15{height: 100%; min-height: calc(100vh - 50px);}

    .sign-in-section.scale15 .heading-div .welcome-title{font-size: 90px;}
    .sign-in-section.scale15 .sign-in-card{width: 100%;}
    .sign-in-section.scale15,     .main-card .card-form.scale15, .main-card.scale15 .card-form{width: 80%;}
    .sign-in-section.scale15 .sign-in-card .number-pad-card .number-card{height: calc(100vw - 80vw); font-size: 70px;}
    .sign-in-section.scale15 .sign-in-card .number-pad-card .option-btn{font-size: 46px;}
    .sign-in-section.scale15 .sign-in-card .display-div .value{height: 130px; font-size: 80px;}
    .sign-in-section.scale15 .sign-in-card .display-div .backspace-btn{height: 130px;}
    .sign-in-section.scale15 .sign-in-card .display-div .backspace-btn .arrow{font-size: 60px;}
    .sign-in-section.scale15 .sign-in-card .display-div .backspace-btn{min-width: 120px;}
    .sign-in-section.scale15 .heading-div .sign-in-msg{font-size: 45px; line-height: 60px;}


    .main-card-column.scale15 .welcome-title{font-size: 55px; max-width: 60%;}

    .main-card-column.scale15 .welcome-back{font-size: 46px; padding-top: 35px; padding-bottom: 35px;}

    .scale15 .card-body{padding: 35px;}

    .scale15 .custome-label{font-size: 40px;}
    .big-p-font{font-size: 40px;}
    .scale15 .form-control{height: 80px; font-size: 40px;}
    .scale15 .form-group{margin-bottom: 35px;}
    .scale15 .text-danger{font-size: 40px;}
    .scale15  .form-select{height: 80px; font-size: 40px; background-size:30px}
    .scale15 option{font-size: 20px;}
    .scale15 .btn{font-size: 44px}
    .scale15 .back-arrow-ab, .main-card .card-form.scale15 .back-arrow-ab i.icon,.main-card .card-form.scale15 .number-div .number {font-size: 40px;}
    .main-card .card-form.scale15 .back-arrow-ab i.icon{font-size: 40px; height: 80px; width: 80px; min-height: 80px; min-width: 80px;}
    .main-card .card-form.scale15 .icon {font-size: 80px;}

    .new-thank-you.for-big-font .thank-you-text{font-size: 60px !important; max-width: 90%;}
    .new-thank-you.for-big-font .thank-you-text.thank-you-text-2{font-size: 40px !important;}
    .thank-you-div.for-big-font .icon-div .thanks-icon{height: 140px; width: 140px;}
}

@media (min-width:767px) and (max-width:1025px) {
    /* .sign-in-section.scale15 .sign-in-card{width: 400px;}
    .sign-in-section.scale15 .heading-div .welcome-title{font-size: 50px;}
    .sign-in-section.scale15 .heading-div .sign-in-msg{font-size: 26px;}
    .sign-in-section.scale15 .sign-in-card .number-pad-card .number-card{font-size: 34px; height: 100px;}
    .sign-in-section.scale15 .sign-in-card .number-pad-card .option-btn{font-size: 25px;} */

    .main-card .card-form .number-div,.back-arrow-ab{gap: 15px;}
    .main-card:has(.scale15), .main-card.scale15{height: 100%; min-height: calc(100vh - 50px);}

    .sign-in-section.scale15 .heading-div .welcome-title{font-size: 70px;}
    .sign-in-section.scale15 .sign-in-card{width: 100%;}
    .sign-in-section.scale15,     .main-card .card-form.scale15, .main-card.scale15 .card-form{width: 80%;}
    .sign-in-section.scale15 .sign-in-card .number-pad-card .number-card{height: calc(100vw - 80vw); font-size: 50px;}
    .sign-in-section.scale15 .sign-in-card .number-pad-card .option-btn{font-size: 30px;}
    .sign-in-section.scale15 .sign-in-card .display-div .value{height: 130px; font-size: 50px;}
    .sign-in-section.scale15 .sign-in-card .display-div .backspace-btn{height: 130px;}
    .sign-in-section.scale15 .sign-in-card .display-div .backspace-btn .arrow{font-size: 50px;}
    .sign-in-section.scale15 .sign-in-card .display-div .backspace-btn{min-width: 120px;}
    .sign-in-section.scale15 .heading-div .sign-in-msg{font-size: 40px; line-height: 50px;}


    .main-card-column.scale15 .welcome-title{font-size: 38px; max-width: 70%;}

    .main-card-column.scale15 .welcome-back{font-size: 35px; padding-top: 35px; padding-bottom: 35px;}
    .mb-250{margin-bottom: 300px;}
    .scale15 .card-body{padding: 35px;}

    .scale15 .custome-label{font-size: 30px;}
    .big-p-font{font-size: 30px;}
    .scale15 .form-control{height: 80px; font-size: 30px;}
    .scale15 .form-group{margin-bottom: 35px;}
    .scale15 .text-danger{font-size: 30px;}
    .scale15  .form-select{height: 80px; font-size: 28px; background-size:30px}
    .scale15 option{font-size: 20px;}
    .scale15 .btn{font-size: 30px}
    .scale15 .back-arrow-ab, .main-card .card-form.scale15 .back-arrow-ab i.icon,.main-card .card-form.scale15 .number-div .number {font-size: 30px;}
    .main-card .card-form.scale15 .back-arrow-ab i.icon{font-size: 30px; height: 65px; width: 65px; min-height: 65px; min-width: 65px;}
    .main-card .card-form.scale15 .icon {font-size: 65px;}

    .new-thank-you.for-big-font .thank-you-text{font-size: 45px !important; max-width: 100%;}
    .new-thank-you.for-big-font .thank-you-text.thank-you-text-2{font-size: 35px !important;}
    .thank-you-div.for-big-font .icon-div .thanks-icon{height: 110px; width: 110px;}
}
@media (max-width:576px) {
    .employee-average table tbody th{width: 150px;}
}


.signup-main-section .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.signup-main-section .image {
    width: 50%;
    margin-right: 20px;
}

.signup-main-section .image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.signup-main-section .text {
    width: 100%;
}

.signup-main-section .text h2 {
    margin-bottom: 10px;
    color: #fff;
}

.signup-main-section .text p {
    margin-bottom: 20px;
}

.signup-main-section button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.signup-main-section button a {
    text-decoration: none;
    color: #fff;
}

.signup-main-section button:hover {
    background-color: #444;
}

.signup-main-section .text-white{
    color: #39c472 !important;
}


.full-screen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.full-screen-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Change from cover to contain */
  object-position: center;
}

@media (min-width: 768px) {
  .full-screen-image-container {
    position: fixed;
  }
}
tbody.show-border > tr > td{
	border-width: 0 var(--bs-border-width) !important;
}
/* Custom CSS */
.fc-daygrid-day, .twt-calendar-td {
  height: 140px; /* Set your desired height */
  overflow: hidden; /* Optional: hides content that overflows */
}
.fc .fc-daygrid-day-frame, .twt-calendar-td {
	max-height: 140px;
	height:140px;
}
.event-display-box{
	background-color: white;
    color: black;
    font-size:12px; 
}
/*
.event-display-box.sun{
	background-color: #f5f5dc;
    color: black;
}
.event-display-box.mon{
	background-color: #e8f5e9;
    color: black;
}
.event-display-box.tue{
	background-color: #e0f7fa;
    color: black;
}
.event-display-box.wed{
	background-color: #f1c40f;
    color: white;
}
.event-display-box.fri{
	background-color: #f5f5f5;
    color: black;
}
.event-display-box.sat{
	background-color: #1abc9c;
     color: white;
}
*/ 
.fc-daygrid-event{
	border: 0px;
}
.fc-daygrid-day-top{
	display:none !important;
}
.twt-calendar.border-bottom{
	border-bottom:1px solid #ddd;
	min-height:30px;
}
.twt-calendar.only-border-bottom{
	border-bottom:1px solid #ddd;
}
.twt-calendar .blue-color{
	color:#2962ff
}
.sms-setting-label{
	text-align: right;
    margin-top: 5px;
}
.font-bold{
	font-weight:bold;
}
.w-40{
	width:40% !important;
}
.w-50{
	width:50% !important;
}
.w-60{
	width:60% !important;
}
.w-55{
	width:55% !important;
}
.w-70{
	width:70% !important;
}
.w-25{
	width:25% !important;
}
.w-80{
	width:80% !important;
}
.w-20{
	width:20% !important;
}
.star {
  cursor: pointer;
  font-size: 7rem;
  margin: 7px;
}
.w-30{
	width:30% !important;
}
.side-by-side-section{
  display: flex;
  flex-wrap: wrap;
}
.payment-button-min-height{
	min-height: 6em !important;
}
.payment-button-info-min-height{
	min-height: 6em !important; 
}
.please-rate-rating-color{
	color:#2962ff;
}
.service-info-table thead th:last-child{
	border-right: 1px dashed black !important;
}
.service-info-table thead th:first-child{
	border-left: 1px dashed black !important;
}
.service-info-table tbody tr td:last-child{
	border-right: 1px dashed black !important;
}
.service-info-table tbody tr td:first-child{
	border-left: 1px dashed black !important;
}
.service-info-table tfoot tr td{
	border-left: 1px dashed black !important;
	border-right: 1px dashed black !important;
}


/* NestedMenu.css */
.nested-menu {
  position: relative;
}

.nested-menu > .nav-item {
  position: relative;
}

.nested-menu .sub-menu {
  position: absolute;
  left: 10%;
  top: 10;
  width: 200px; /* Adjust as needed */
  display: none;
  transition: transform 0.3s ease;
  transform: translateX(-20px); /* Initial off-screen position */
}

.nested-menu .sub-menu.show {
  display: block;
  transform: translateX(0); /* Slide into view */
}